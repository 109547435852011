/*Reset*/
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-weight: inherit;
  -webkit-appearance: none !important;
}

.btn {
  scroll-behavior: smooth;
}

/*Variables*/
:root {
  --greenyellow: #d5df2b;
  --gray: #e7e7e7;
  --black: #000000;
  --white: #ffffff;
  --darkgray: #808080;
}

/*Layout Clases*/
.father {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: relative;
}
.max-w {
  position: relative;
  width: 100%;
  max-width: 1500px;
  z-index: 10;
}
section {
  width: 100%;
}
.full-row {
  width: 100%;
}
.center {
  text-align: center;
}
.flex {
  display: flex;
}
.flex.align-center {
  align-items: center;
}
.flex.justify-center {
  justify-content: center;
}
.flex.justify-end {
  justify-content: flex-end;
}
.flex.column {
  flex-direction: column;
}
.flex.space-between {
  justify-content: space-between;
}
.flex.space-around {
  justify-content: space-around;
}
.flex.start-bottom {
  align-items: flex-end;
}
.flex .col1 {
  width: 10%;
}
.flex .col2 {
  width: 20%;
}
.flex .col3 {
  width: 30%;
}
.flex .col4 {
  width: 40%;
}
.flex .col45 {
  width: 45%;
}
.flex .col5 {
  width: 50%;
}
.flex .col6 {
  width: 60%;
}
.flex .col7 {
  width: 70%;
}
.flex .col8 {
  width: 80%;
}
.flex .col9 {
  width: 90%;
}
img {
  max-width: 100%;
}

@media (max-width: 600px) {
  .center-mobile {
    text-align: center;
  }
  .justify-center-mobile {
    justify-content: center;
  }
  .no-lm-mobile {
    margin-left: 0px !important;
  }
  .mobile-column {
    flex-direction: column;
  }
  .reverse-mobile-column {
    flex-direction: column-reverse;
  }
  .mobile-column .col1,
  .mobile-column .col2,
  .mobile-column .col3,
  .mobile-column .col4,
  .mobile-column .col45,
  .mobile-column .col5,
  .mobile-column .col6,
  .mobile-column .col7,
  .mobile-column .col8,
  .mobile-column .col9,
  .reverse-mobile-column .col1,
  .reverse-mobile-column .col2,
  .reverse-mobile-column .col3,
  .reverse-mobile-column .col4,
  .reverse-mobile-column .col45,
  .reverse-mobile-column .col5,
  .reverse-mobile-column .col6,
  .reverse-mobile-column .col7,
  .reverse-mobile-column .col8,
  .reverse-mobile-column .col9 {
    width: 100% !important;
  }
}

/*margins*/
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}
.mb-6 {
  margin-bottom: 60px;
}
.mb-7 {
  margin-bottom: 70px;
}
.mb-8 {
  margin-bottom: 80px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}

@media (max-width: 600px) {
  .mb-3,
  .mb-4 {
    margin-bottom: 20px;
  }
  .mb-5,
  .mb-6 {
    margin-bottom: 30px;
  }
  .mb-8 {
    margin-bottom: 60px;
  }
  .mobile-mb2 {
    margin-bottom: 20px;
  }
  .mobile-mb3 {
    margin-bottom: 30px;
  }
  .mobile-mb4 {
    margin-bottom: 40px;
  }
  .mobile-mb5 {
    margin-bottom: 50px;
  }
  .mobile-mb6 {
    margin-bottom: 60px;
  }
  .mobile-mb7 {
    margin-bottom: 70px;
  }
  .mobile-mb8 {
    margin-bottom: 80px;
  }
  .mobile-mtop {
    margin-top: 40px;
  }
  .not-m-mobile {
    margin-bottom: 0px !important;
  }
}

/*paddings*/
.pr-1 {
  padding-right: 10%;
}
.pr-2 {
  padding-right: 20%;
}
.pl-1 {
  padding-left: 10%;
}
.pl-2 {
  padding-left: 20%;
}
.pl-textarea {
  padding-left: 25px;
}
@media (max-width: 600px) {
  .pr-1 {
    padding-right: 0%;
  }
  .pr-2 {
    padding-right: 0%;
  }
  .pl-1 {
    padding-right: 0%;
  }
  .pl-2 {
    padding-right: 0%;
  }
  .pl-textarea {
    padding-left: 0px;
  }
}
