#produccionPage p {
  max-width: 400px;
}

#produccion-hero {
  width: 100%;
}
#produccion-hero .left-block {
  width: 40%;
}
#produccion-hero .right-block {
  width: 60%;
}
#title-produccion-hero {
  width: 100%;
  max-width: 480px;
}
@media (max-width: 1140px) {
  #produccion-hero .left-block,
  #produccion-hero .right-block {
    width: 50%;
  }
}

@media (max-width: 650px) {
  #produccion-hero {
    padding-top: 40px;
  }
  #produccion-hero .left-block,
  #produccion-hero .right-block {
    width: 100%;
  }
  #title-produccion-hero {
    padding: 10px 20px 0px 20px;
  }
}

/*rows*/
.rows-production p {
  max-width: 492px;
}
.rows-production .super-big {
  margin-right: 14px;
}

.rows-production .col45 {
  position: relative;
}
.rows-production .point {
  position: absolute;
  bottom: 80px;
  z-index: -1;
}
.point.right {
  right: -272px;
}
.point.left {
  left: -272px;
}

@media (max-width: 1228px) {
  .rows-production .point {
    bottom: 40px;
  }
}
@media (max-width: 1000px) {
  .rows-production .point {
    display: none;
  }
}
