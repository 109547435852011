/*hero*/
#hero-home {
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20vh;
  background-color: var(--black);
}
#hero-home div:first-child {
  z-index: 10;
}
.hero-bar {
  width: 100%;
  background: rgba(11, 11, 11, 0.49);
  text-align: center;
  padding: 20px;
}
.video-hero {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 1;
  left: 0px;
  top: 0px;
}
.video-hero.desktop {
  display: inherit;
}
.video-hero.mobile {
  display: none;
}

@media (max-width: 1120px) {
  #hero-home {
    height: calc(var(--vh, 1vh) * 100 - 50px) !important;
  }
  .video-hero.desktop {
    display: none !important;
  }
  .video-hero.mobile {
    display: inherit !important;
  }
}

/*Row subittle black*/
#row-home-subititle {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 70px 0px 140px 0px;
  z-index: 0;
}
#row-home-subititle div:first-child,
#row-home-subititle div:last-child {
  width: 28%;
}
.white-line {
  width: 100%;
  height: 2px;
  background-color: var(--white);
}
/*Row values*/
#row-home-values {
  position: relative;
  z-index: 1;
  min-height: 450px;
}
#row-home-values .space-between {
  position: absolute;
  top: -140px;
  left: 0px;
  width: 100%;
  z-index: 9;
  padding: 50px;
  align-items: flex-start;
}
#row-home-values .card {
  width: 31%;
  background-color: var(--white);
}
#row-home-values ul {
  width: 100%;
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
  padding-left: 20px;
}
@media (max-width: 1160px) {
  #row-home-values ul {
    -moz-column-count: 1;
    -moz-column-gap: 0px;
    -webkit-column-count: 1;
    -webkit-column-gap: 0px;
    column-count: 1;
    column-gap: 0px;
  }
  #row-home-values {
    position: relative;
    z-index: 1;
    min-height: 420px;
  }
}

@media (max-width: 900px) {
  #row-home-subititle {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 50px 0px 70px 0px;
    z-index: 0;
  }
  #row-home-values {
    padding: 0px 60px 0px 60px;
  }
  #row-home-values .space-between {
    position: relative;
    top: -40px;
    padding: 0px;
    flex-direction: column;
  }
  #row-home-values .card {
    width: 100%;
    background-color: var(--white);
    margin-bottom: 35px;
  }
  #row-home-values .card:last-child {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 650px) {
  #row-home-values {
    padding: 0px 40px 0px 40px;
  }
}

/*conoce historia*/
#conoce-historia {
  padding: 50px;
  background-image: url("/public/images/fondo-conoce.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
#conoce-historia .slider-wrapper {
  width: 60%;
  padding-right: 2.5%;
}
#conoce-historia .content-wrapper {
  width: 40%;
  padding-left: 2.5%;
}
#conoce-historia h2 {
  line-height: 40px;
}
#conoce-historia span {
  font-size: 30px;
  letter-spacing: 4px;
}

.swiper-button-prev,
.swiper-button-next {
  background: rgba(217, 217, 217, 0.78) !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: black !important;
  font-size: 28px !important;
}
.swiper-pagination-bullet {
  opacity: 1 !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  width: 10px !important;
  height: 10px !important;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: #c4cd3e !important;
}

@media (max-width: 900px) {
  #conoce-historia .mobile-column {
    flex-direction: column;
  }
  #conoce-historia .slider-wrapper {
    width: 100%;
    padding-right: 0%;
  }
  #conoce-historia .content-wrapper {
    width: 100%;
    padding-left: 0%;
  }
  #conoce-historia .mobile-mb4 {
    margin-bottom: 40px;
  }
  #conoce-historia {
    padding: 50px 30px;
  }
}

@media (max-width: 650px) {
  #conoce-historia {
    padding: 40px 20px;
  }
}

/*producción*/
#produccion-home .card {
  width: 48%;
}

@media (max-width: 650px) {
  #produccion-home .card {
    width: 100%;
  }
  #produccion-home .card:first-child {
    margin-bottom: 30px;
  }
}

/* practicas */
#practicas {
  background-image: url("/public/images/background-practicas.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 600px;
  position: relative;
}
.data-practicas {
  text-align: center;
  padding: 100px 50px 50px 50px;
  width: 1030px;
  height: 535px;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  right: 5%;
  background-image: url("/public/images/background-practicas-text.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 900px) {
  .data-practicas {
    right: 0%;
    width: 100%;
    height: 90%;
  }
  .data-practicas .large {
    font-size: 28px;
  }
}

/*catalogo*/
#home-catalogo {
  margin-bottom: 60px;
}
.catalogo-slider,
.catalogo-data {
  width: 50%;
}
.item-catalogo-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.item-catalogo-wrapper p {
  max-width: 500px;
}
.item-arrow {
  width: 10%;
}
.triangle {
  position: relative;
  top: 2px;
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-right: 0;
  border-left: 22px solid var(--greenyellow);
}
.item-data {
  width: 90%;
}
@media (max-width: 900px) {
  #home-catalogo {
    margin-bottom: 40px;
  }
}
@media (max-width: 650px) {
  .catalogo-slider,
  .catalogo-data {
    width: 100%;
  }
}
