header {
  background-color: var(--black);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
}
.header-wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0px 50px;
  height: 100%;
}
.logo-wrapper {
  width: 20%;
  display: flex;
  align-items: center;
}
.logo-wrapper img {
  height: auto;
  width: 100%;
}
.logo-wrapper a {
  display: flex;
  align-items: center;
}
nav {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
nav a {
  padding: 20px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburger {
  display: none;
}
.hamburger div {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
nav.mobile {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 50px) !important;
  display: flex;
  bottom: 0px;
  left: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  background-color: var(--black);
}
nav.mobile a {
  height: auto;
}

@media (max-width: 900px) {
  .hamburger {
    display: block;
  }
  nav {
    display: none;
  }
  .header-wrapper {
    padding: 0px 30px;
  }
  .logo-wrapper {
    width: 50%;
  }
  header {
    height: 50px;
  }
}
@media (max-width: 650px) {
  .header-wrapper {
    padding: 0px 20px;
  }
}
