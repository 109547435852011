#contacto-wrapper {
  background-image: url("/public/images/contacto-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5vw;
}
#contacto-wrapper h1 {
  color: var(--white);
  position: absolute;
  left: 50px;
  bottom: 10vh;
}
.google-maps-wrap {
  background: #110f10;
  padding: 10px 20px;
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  color: var(--white);
  font-family: "AvenirHeavy";
  position: absolute;
  left: 0px;
  top: 5vh;
}
.google-maps-wrap svg {
  margin-left: 20px;
}
form {
  width: 40%;
  box-sizing: border-box;
  padding: 50px 30px;
  background-color: var(--white);
  position: relative;
}
input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 30px;
}
textarea {
  border-radius: 0px;
  width: 100%;
  min-height: 200px;
  border: 2px solid black;
  padding: 10px;
  margin-bottom: 40px;
}
form button {
  background-color: #110f10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 20px;
  color: white;
  border: 0px;
  width: 50%;
}

@media (max-width: 900px) {
  #contacto-wrapper h1 {
    position: relative;
    left: 0px;
    top: 0px;
    font-size: 40px;
  }
  .google-maps-wrap {
    position: relative;
    left: 0px;
    top: 0px;
    padding: 10px 20px;
    display: flex;
    width: auto;
  }
  #contacto-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    padding: 30px 30px;
  }
  form {
    width: 100%;
    margin-top: 40px;
    padding: 30px 20px;
  }
  form button {
    width: auto;
  }
}

.bloque-datos-contacto {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 0px;
  top: 0px;
}

@media (max-width: 650px) {
  #contacto-wrapper {
    padding: 30px 20px;
  }
}
