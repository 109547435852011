/*Background colors sections*/
.back-black {
  background-color: var(--black);
}
.back-gray {
  background-color: var(--gray);
}
.back-white {
  background-color: var(--white);
}
.back-pale {
  background-color: var(--paleyellow);
}

/*Main paddings for header*/
main {
  padding-top: 70px;
  z-index: 0;
}
header {
  z-index: 99;
}
.normal-pad {
  padding: 50px;
}
.no-pad-bottom {
  padding-bottom: 0px !important;
}

@media (max-width: 900px) {
  main {
    padding-top: 50px;
  }
  .normal-pad {
    padding: 50px 30px;
  }
}
@media (max-width: 650px) {
  .normal-pad {
    padding: 40px 20px;
  }
}

/*card*/
.card {
  width: 100%;
  padding: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--white);
}

/*button*/
.btn {
  background-color: var(--greenyellow);
  display: inline-block;
  padding: 10px 40px;
  font-size: 17px;
  color: var(--black);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  transition: all ease-in-out 0.2s;
  text-decoration: none;
}

.btn:hover {
  background-color: var(--black);
  color: var(--greenyellow);
}

/*Yellow bar*/
.yellow-bar {
  background-color: #f6f8d2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}
.yellow-bar h3 {
  font-family: "AvenirHeavy";
  font-size: 30px !important;
  width: 100%;
  max-width: 700px;
  line-height: 2.7rem;
}
