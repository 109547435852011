/*Fonts Face*/

/*Montserrat*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap");

/*Avenir*/
@font-face {
  font-family: "AvenirBook";
  src: local("AvenirBook"), url(../fonts/Avenir-Book.woff) format("woff"),
    local("AvenirBook"), url(../fonts/Avenir-Book.woff2) format("woff2");
}
@font-face {
  font-family: "AvenirHeavy";
  src: local("AvenirHeavy"), url(../fonts/Avenir-Heavy.woff) format("woff"),
    local("AvenirHeavy"), url(../fonts/Avenir-Heavy.woff2) format("woff2");
}

/*Brocode*/
@font-face {
  font-family: "BrocodeBold";
  src: local("BrocodeBold"),
    url(../fonts/BrocodeDisplay-Bold.woff) format("woff"), local("BrocodeBold"),
    url(../fonts/BrocodeDisplay-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "BrocodeMedium";
  src: local("BrocodeMedium"),
    url(../fonts/BrocodeDisplay-Medium.woff) format("woff"),
    local("BrocodeMedium"),
    url(../fonts/BrocodeDisplay-Medium.woff2) format("woff2");
}

/*Font Styles*/
a {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.7rem;
}
b {
  font-weight: 700;
}
li {
  margin-bottom: 10px;
}
p.large {
  font-size: 26px;
}
p.super-big {
  font-family: "AvenirHeavy";
  font-size: 180px;
  line-height: 130px;
  color: var(--darkgray);
}

main h1 {
  font-family: "AvenirHeavy";
  font-size: 64px;
}
h2 {
  font-family: "AvenirHeavy";
  font-size: 40px;
}
h2.big {
  font-family: "BrocodeBold";
  font-size: 60px;
}
h2 span {
  font-family: "BrocodeMedium";
}
h2.produccion-type {
  font-family: "BrocodeMedium";
}
h3 {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700 !important;
}
h3.light {
  font-family: "AvenirBook";
  font-size: 30px;
}
h3.large {
  font-family: "AvenirHeavy";
  font-size: 30px;
}
.rows-production h3 {
  text-transform: uppercase;
}

.highlighted {
  background-color: var(--greenyellow);
  color: var(--black);
  padding: 10px 20px;
  display: inline-block;
}

/*Home especial fonts*/
ul li {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

/*colors*/
.white {
  color: var(--white);
}
.darkgray {
  color: var(--darkgray) !important;
}

/*Header fonts*/
nav a {
  color: var(--white);
  text-decoration: none;
  transition: all ease-in-out 0.2s;
}
nav a:hover {
  color: var(--greenyellow);
}
nav a.active {
  background-color: var(--greenyellow);
  color: var(--black);
}

/*Footer fonts*/
footer h2 {
  color: var(--white);
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
footer a {
  text-decoration: none;
  font-size: 15px;
}
footer a,
footer p {
  color: rgba(255, 255, 255, 0.8);
}

/*Balck Title*/
.black-title {
  background-color: var(--black);
  display: inline-block;
  padding: 10px 50px;
  text-align: center;
}
.black-title h2 {
  font-size: 60px;
  font-family: "BrocodeMedium";
  color: var(--white);
}
@media (max-width: 900px) {
  .black-title {
    padding: 10px 30px;
  }
  .black-title h2 {
    font-size: 40px;
  }
  p.large {
    font-size: 20px;
  }
  h2.big {
    font-size: 40px;
  }
}

@media (max-width: 650px) {
  main h1 {
    font-size: 44px;
  }
  .black-title {
    padding: 10px 20px;
  }
  .black-title h2 {
    font-size: 36px;
  }
  p.large {
    font-size: 18px;
  }
  h2.big {
    font-size: 36px;
  }
  .yellow-bar h3 {
    font-size: 24px !important;
    line-height: 2rem;
  }
  .super-big {
    font-size: 150px;
  }
  h3.large {
    font-size: 26px;
  }
}

/*catalogo home*/
.item-catalogo-wrapper h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  display: inline;
}
.item-catalogo-wrapper h5:hover {
  color: var(--greenyellow);
}
.text-underline {
  text-decoration: underline;
}

/*contacto*/
form,
input,
textarea,
button {
  -webkit-appearance: none !important;
  -moz-appearance: none;
}
input,
textarea {
  font-size: 18px;
  font-family: "AvenirHeavy";
  color: var(--black);
}
form button {
  font-size: 22px;
  font-family: "AvenirHeavy";
  color: var(--white);
  cursor: pointer;
}
form button:hover {
  text-decoration: underline;
}
