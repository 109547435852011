footer {
  background-color: var(--black);
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  z-index: 0;
}
.footer-wrapper {
  display: flex;
  justify-content: space-between;
}
.footer-data-wrapper {
  width: 70%;
  padding: 70px 50px;
}
.footer-content-wrapper {
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  position: relative;
  align-items: center;
}
footer .line {
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
}

.footer-content-wrapper .col1 {
  width: 40%;
}
.footer-content-wrapper .col1 img {
  width: 100%;
}

.footer-content-wrapper .col2 {
  display: flex;
  width: 60%;
  justify-content: flex-end;
}

.footer-content-wrapper .data1 {
  width: 48%;
}
.footer-content-wrapper .data2 {
  width: 30%;
}

footer .flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
footer .flex-row.flex-start {
  align-items: flex-start !important;
}
.footer-svg-wrapper {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}
.footer-content-wrapper:last-child {
  margin-bottom: 0px;
}
.footer-svg-wrapper svg {
  height: 100%;
  width: auto;
}

.footer-map-wrapper {
  width: 30%;
  overflow: hidden;
}
footer iframe {
  border: 0px !important;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
@media (max-width: 1220px) {
  .footer-content-wrapper .data1 {
    width: 50%;
  }
}
@media (max-width: 1090px) {
  .footer-content-wrapper {
    flex-direction: column;
  }
  .footer-content-wrapper .col1,
  .footer-content-wrapper .col2 {
    width: 100%;
  }
  .footer-content-wrapper .col1 img {
    width: 50%;
    margin-bottom: 30px;
  }
  .footer-content-wrapper .col2 {
    justify-content: flex-start;
  }
}
@media (max-width: 900px) {
  .footer-data-wrapper {
    padding: 50px 30px;
  }
  footer .data1,
  footer .data2 {
    width: 50% !important;
  }
}
@media (max-width: 650px) {
  footer .col2 {
    flex-direction: column;
  }
  footer .col1 img {
    width: 90% !important;
  }
  footer .data1,
  footer .data2 {
    width: 100% !important;
  }
  footer .data1 {
    margin-bottom: 20px;
  }
  .footer-data-wrapper {
    width: 65%;
    padding: 50px 20px;
  }
  .footer-map-wrapper {
    width: 35%;
  }
}

footer a:hover {
  text-decoration: underline;
}
