#hero-catalogo {
  background-image: url("/public/images/catalogo-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
@media (max-width: 650px) {
  #hero-catalogo {
    height: calc(var(--vh, 1vh) * 100 - 50px) !important;
  }
}
#hero-catalogo h3 {
  font-family: "AvenirBook";
  font-size: 30px !important;
  width: 100%;
  max-width: 500px;
}

#catalogoPage .swiper {
  padding: 50px 100px 100px 100px;
  margin-bottom: 50px;
}

#catalogoPage .card h2 {
  color: var(--greenyellow);
  font-size: 120px;
  letter-spacing: 0px;
  font-family: "BrocodeBold";
  line-height: 110px;
}
#catalogoPage .card h3 {
  font-size: 40px;
  position: relative;
  right: 30px;
  bottom: 6px;
  text-transform: uppercase;
  font-family: "AvenirHeavy";
}
#catalogoPage img {
  width: 100%;
}
@media (max-width: 1400px) {
  #catalogoPage .card h2 {
    font-size: 100px;
  }
  #catalogoPage .card h3 {
    font-size: 30px;
  }
}
@media (max-width: 1150px) {
  #catalogoPage .card h2 {
    font-size: 80px;
    line-height: 80px;
  }
  #catalogoPage .card h3 {
    font-size: 26px;
    right: 20px;
  }
}
@media (max-width: 1150px) {
  #catalogoPage .swiper {
    padding: 50px 70px 100px 70px;
  }
}

#catalogoPage .swiper-button-prev,
#catalogoPage .swiper-button-next {
  background: rgba(0, 0, 0, 0.78) !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}
#catalogoPage .swiper-button-next:after,
#catalogoPage .swiper-button-prev:after {
  color: #ffffff !important;
}
#catalogoPage .swiper-pagination-bullet {
  background: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
#catalogoPage .swiper-pagination-bullet-active {
  background: #000000 !important;
}

#catalogoPage .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 50px !important;
}

#catalogo-slider-wrapper {
  width: 100%;
  position: relative;
}

#catalogo-slider-wrapper .yellow-line {
  width: 100%;
  height: 70px;
  background-color: var(--greenyellow);
  position: absolute;
  left: 0px;
  bottom: 240px;
  z-index: 0;
}
