.flex-ro {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.flex-ro img {
  margin-right: 20px;
  width: 28px;
}
.flex-ro a {
  color: var(--black);
  text-decoration: none;
}
.text-puntos {
  max-width: 700px;
}
.map-background {
  width: 100%;
  height: 500px;
  position: relative;
}
.color-bg {
  background-color: var(--black);
  width: 94%;
  height: 66%;
  position: absolute;
  bottom: 0px;
  left: 0%;
}
.inline p {
  display: inline-block;
}
.mr-10 {
  margin: 0px 10px 0px 0px !important;
}
.mrl-10 {
  margin: 0px 10px !important;
}
#puntosdeventaPage iframe {
  border: 0px !important;
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  left: 25px;
  bottom: 25px;
  position: absolute;
}

#puntosdeventaPage .left-block,
#puntosdeventaPage .right-block {
  width: 50%;
}

#puntosdeventaPage .left-block {
  padding-right: 40px;
}
#puntosdeventaPage .right-block {
  padding-left: 40px;
}

.invertido .right-block {
  padding-left: 0px !important;
}
.invertido .left-block {
  width: calc(50% - 40px) !important;
}

.puntos-de-venta-wrapper p,
.puntos-de-venta-wrapper a {
  font-size: 26px !important;
}

@media (max-width: 768px) {
  #puntosdeventaPage .left-block,
  #puntosdeventaPage .right-block {
    width: 100% !important;
  }
  .map-background {
    height: 400px;
  }
  #puntosdeventaPage iframe {
    height: 400px;
  }
  #puntosdeventaPage .right-block {
    padding-left: 0px;
  }
  #puntosdeventaPage .left-block {
    padding-right: 00px;
  }
  .map {
    padding-right: 40px !important;
  }
  .puntos-de-venta-wrapper p,
  .puntos-de-venta-wrapper a {
    font-size: 18px !important;
  }
  .invertido .left-block {
    order: 2;
  }
  .invertido .right-block {
    order: 1;
  }
  #puntosdeventaPage .mb-8:last-child {
    margin-bottom: 0px !important;
  }
}
